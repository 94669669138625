import http from '../httpService';
import { EntityDto } from '../dto/entityDto';
import { PagedResultDto } from '../dto/pagedResultDto';
import { PagedResultRequestDto } from "./pagedResultRequestDto";
import { ServiceBase } from './serviceBase';
import Endpoint from '../endpoint';
import { AxiosInstance } from 'axios';
import { CrudConsts } from '../../stores/crudStoreBase';

export class CrudServiceBase<TDto extends EntityDto, TCreateDto = TDto> extends ServiceBase {
    defaultRequest: any = { keyword: "", maxResultCount: CrudConsts.PAGE_SIZE, skipCount: 0 };
    internalHttp: AxiosInstance;

    constructor(endpoint: Endpoint, overrideHttp: AxiosInstance = http ) {
        super(endpoint);
        this.internalHttp = overrideHttp;
    }

    public async get(entityDto: EntityDto): Promise<TDto> {
        let result = await this.internalHttp.get(this.endpoint.Get(), { params: entityDto });
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async getAll(pagedFilterAndSortedRequest: PagedResultRequestDto = this.defaultRequest): Promise<PagedResultDto<TDto>> {
        let result = await this.internalHttp.get(this.endpoint.GetAll(), { params: pagedFilterAndSortedRequest });
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async create(createUserInput: TCreateDto) {
        let result = await this.internalHttp.post(this.endpoint.Create(), createUserInput);
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async update(updateUserInput: TDto) {
        let result = await this.internalHttp.put(this.endpoint.Update(), updateUserInput);
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async delete(entityDto: TDto) {
        let result = await this.internalHttp.delete(this.endpoint.Delete(), { params: entityDto });
        return !!result.data && !!result.data.result ? result.data.result : result.data;
    }

    public async getAllDtos(filters: string | undefined = undefined, count: number | undefined = undefined, skip: number | undefined = undefined) {
        let result = await this.internalHttp.get(this.endpoint.Custom(`${this.endpoint.dataSet}/AllDtos?`,true),{params:{
            filters,
            count,
            skip
        }});
        return result.data;
    }

    public async getDto(entityDto: EntityDto): Promise<TDto> {
        return this.get(entityDto);
        // let result = await this.internalHttp.get(this.endpoint.Custom(`${this.endpoint.dataSet}/Dto/${entityDto.id}`,true));
        // return result.data;
    }
}


