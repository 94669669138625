import { Redirect, Route } from 'react-router-dom';
import { AzureB2CStorage } from '../../scenes/Login/AzureB2C/azureResponse';
import { AzureB2CStorageKey } from '../../scenes/Login/AzureB2C/signInButton';

declare var abp: any;

const ProtectedRoute = ({ path, component: Component, permission, render, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={props => {
        let json = localStorage.getItem(AzureB2CStorageKey);
        if (json) {
          let obj: AzureB2CStorage = JSON.parse(json) as AzureB2CStorage;
          abp.session.userId = obj.user.id;
        }
        let componentRendered = Component ? <Component {...props} /> : render(props);

        if (abp.session.userId) {
          return <>{componentRendered}</>;
        }
        return <>
          <Redirect
            to={{
              pathname: '/user/login',
              state: { from: props.location },
            }}
          />
        </>
      }}
    />
  );
};

export default ProtectedRoute;
