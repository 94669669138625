import { CrudStoreBase } from './crudStoreBase';
import { ComponentDto } from '../services/component/dto/componentDto';
import ComponentService from '../services/component/componentService';
import { DoseType } from '../services/dto/doseType';
import { ComponentType } from '../services/dto/componentType';
import { defaultTreatment } from './treatmentStore';

class ComponentStore extends CrudStoreBase<ComponentDto>{
	constructor() {
		super(ComponentService, defaultComponent)
	}
}

export const defaultReplacement = {
	quantity: 0,
	price: 0,
	name: "",
	id: 0,
	isArchival: false,
}

export const defaultComponentTreatmentItem = {
	treatmentId: 0,
	treatment: defaultTreatment,
	doseType: DoseType[DoseType.None],
	skipBefore: 0,
	skipAfter: 0,
	id: 0,
	description: '',
	isArchival: false,
}

export const defaultComponent = {
	id: 0,
	volume: 0,
	defaultBasic:0,
	defaultOptimal:0,
	defaultMaximum:0,
	replacements: [],
	treatments: [],
	componentType: ComponentType[ComponentType.General],
	description: "",
	name: "",
	price: 0,
	linkToFile: "",
}

export default ComponentStore;