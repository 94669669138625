import { CrudStoreBase } from './crudStoreBase';
import TreatmentService from '../services/treatment/treatmentService';
import { TreatmentDto } from '../services/treatment/dto/treatmentDto';

class TreatmentStore extends CrudStoreBase<TreatmentDto>{
	constructor() {
		super(TreatmentService, defaultTreatment)
	}
}

export const defaultTreatment = {
	id: 0,
	medicineType: "",
	linkToFile: "",
	isArchival: false,
	name: ""
}

export default TreatmentStore;