export default class Stores {
  static AuthenticationStore: string = 'authenticationStore';
  static RoleStore: string = 'roleStore';
  static TenantStore: string = 'tenantStore';
  static UserStore: string = 'userStore';
  static SessionStore: string = 'sessionStore';
  static AccountStore: string = 'accountStore';
  static SearchStore: string = 'searchStore';
  static EventStore: string = 'eventStore';
  static UserCrudStore: string = 'userCrudStore';

  static CustomerGroupStore: string = 'customerGroupStore';
  static LanguageStore: string = 'languageStore';
  static CountryStore: string = 'countryStore';
  // Domain specific
  static CustomerStore: string = 'customerStore';
  static ComponentStore: string = 'componentStore';
  static AnimalStore: string = 'animalStore';
  static WaterUsageStore: string = 'waterUsageStore';
  static ProgramStore: string = 'programStore';
  static CalculationStore: string = 'calculationStore';
  static TreatmentStore: string = 'treatmentStore';
  static RaceStore: string = 'raceStore';
  static SolutionStore: string = 'solutionStore';
  static ProfitStore: string = 'profitStore';
  static PatternStore: string = 'patternStore';
}