import { ODataServiceBase } from '../base/oDataServiceBase';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { PatternDto } from './dto/patternDto';

export class PatternService extends ODataServiceBase<PatternDto> {
    constructor() {
        super(Endpoint.Pattern);
        this.internalHttp = httpApi;
    }
    public async getAllDtos(): Promise<{ totalCount: any; items: any; }> {        
        let result = await this.internalHttp.get(this.endpoint.GetAll());
        return result.data;
    } 
    public async getDto(entityDto: PatternDto): Promise<any> {
        let result = await this.internalHttp.get(this.endpoint.Get(), {params:{"id": entityDto.id}});
        return result.data;
    }
    public async delete(entityDto: PatternDto): Promise<any> {
        let result = await this.internalHttp.delete(this.endpoint.Delete(),{params:{"id": entityDto.id}});
        return result.data;
    }
    public async update(updateUserInput: PatternDto): Promise<any> {
        let result = await this.internalHttp.put(this.endpoint.Update(), updateUserInput);
        return result.data;
    }
}
export default new PatternService();