import { action, observable } from 'mobx';

import { CreateOrUpdateUserInput } from '../services/user/dto/createOrUpdateUserInput';
import { EntityDto } from '../services/dto/entityDto';
import { GetRoles } from '../services/user/dto/getRolesOuput';
import { GetUserOutput } from '../services/user/dto/getUserOutput';
import { PagedResultDto } from '../services/dto/pagedResultDto';
import { PagedUserResultRequestDto } from '../services/user/dto/PagedUserResultRequestDto';
import { UpdateUserInput } from '../services/user/dto/updateUserInput';
import userService, { ClientIdDto } from '../services/user/userService';
import { CreateUserInput } from '../services/user/dto/createUserInput';
import { IDropdownOption } from '@fluentui/react';
import { CrudConsts } from './crudStoreBase';

export enum UserFilterStatus {
  Pending = 1,
  Active = 2,
  NotActive = 3
}

class Dictionary<TValue>{
  private any = {}

  addOrUpdate(key: any, value: TValue) {
    this.any[key.toString()] = value
  }

  get(key: any): TValue {
    return this.any[key.toString()] as TValue
  }
}

class UserSet {
  result: PagedResultDto<GetUserOutput>
  page: number
  constructor(result: PagedResultDto<GetUserOutput>, page: number) {
    this.page = page;
    this.result = result
  }
}

let defaultValue: CreateUserInput = {
  id: 0,
  userName: '',
  name: '',
  surname: '',
  emailAddress: '',
  isActive: false,
  roleNames: [],
  password: '',
  confirm: '',
  phoneNumber: '',
  isEmailConfirmed: false
}

class UserStore {
  defaultValue: CreateUserInput
  constructor() {
    this.defaultValue = defaultValue;
    this.createDefault()
  }
  @observable users!: PagedResultDto<GetUserOutput>;
  @observable editUser!: CreateOrUpdateUserInput;
  @observable roles: GetRoles[] = [];
  @observable model!: CreateUserInput

  page: number = 0;
  statusUser: Dictionary<UserSet> = new Dictionary<UserSet>();

  @action
  createDefault() {
    let text = JSON.stringify(this.defaultValue)
    this.model = JSON.parse(text) as CreateUserInput
  }

  @action
  async create(createUserInput: CreateOrUpdateUserInput): Promise<any> {
    let result = await userService.create(createUserInput);
    if (this.users && this.users.items)
      this.users.items.push(result);
    return result;
  }

  @action
  async update(updateUserInput: UpdateUserInput) {
    let result = await userService.update(updateUserInput);
    this.users.items = this.users.items.map((x: GetUserOutput) => {
      if (x.id === updateUserInput.id) x = result;
      return x;
    });
  }

  @action
  async delete(entityDto: EntityDto) {
    await userService.delete(entityDto);
    this.users.items = this.users.items.filter((x: GetUserOutput) => x.id !== entityDto.id);
  }

  @action
  async getRoles() {
    let result = await userService.getRoles();
    this.roles = result;
  }

  @action
  async get(entityDto: EntityDto) {
    let result = await userService.get(entityDto);
    this.editUser = result;
  }

  @action
  async createUser() {
    this.editUser = {
      userName: '',
      name: '',
      surname: '',
      emailAddress: '',
      isActive: false,
      roleNames: [],
      isEmailConfirmed: false,
      phoneNumber: '',
      password: '',
      id: 0,
    };
    this.roles = [];
  }

  @action
  async getAllWithKeyword(keyword: string) {
    let request = { keyword: keyword, maxResultCount: CrudConsts.BULK_SIZE, skipCount: 0 };
    let result = await userService.getAll(request);
    return !!result && !!result.items ? result.items : [];
  }

  @action
  async getAll(status: UserFilterStatus) {
    let keyword = `$status=${status}`
    let userSet = this.statusUser.get(status)
    let refresh = false
    if (userSet) {
      refresh = true
      this.users = userSet.result
      this.page = userSet.page
    }
    else {
      this.page = 0
    }

    if (refresh && this.users.totalCount <= this.users.items.length) {
      let page = this.page > 0 ? (this.page - 1) : 0;
      let request = { keyword: keyword, maxResultCount: CrudConsts.PAGE_SIZE, skipCount: page * CrudConsts.PAGE_SIZE }
      let result = await userService.getAll(request);
      result.items.forEach(x => {
        if (this.users.items.findIndex(y => y.id === x.id) === -1) {
          this.users.items.push(x)
        }
      })
      // TODO not see new element when is added - everything is incremented but row is not shown 
      this.users = { totalCount: result.totalCount, items: this.users.items };
      this.statusUser.addOrUpdate(status, new UserSet(this.users, this.page))
      return;
    }

    while (!refresh || this.users.totalCount > this.users.items.length) {
      let request = { keyword: keyword, maxResultCount: CrudConsts.PAGE_SIZE, skipCount: this.page * CrudConsts.PAGE_SIZE }
      this.page++;
      let result = await userService.getAll(request);
      if (refresh) {
        this.users.items = this.users.items.concat(result.items)
      }
      else {
        this.users = result;
        refresh = true;
      }
    }
    this.statusUser.addOrUpdate(status, new UserSet(this.users, this.page))
  }

  @action
  async getNoAssignedUser(pagedFilterAndSortedRequest: PagedUserResultRequestDto = { keyword: "", maxResultCount: CrudConsts.PAGE_SIZE, skipCount: 0 }) {
    let result = await userService.getNoAssignedUser(pagedFilterAndSortedRequest);
    this.users = result;
  }

  @action
  async getClientForUser(userId: number): Promise<ClientIdDto[]> {
    let result = await userService.getClientForUser(userId);
    return result;
  }

  async changeLanguage(languageName: string) {
    await userService.changeLanguage({ languageName: languageName });
  }

  getOptions(callbackfn?: (value: GetUserOutput) => boolean): IDropdownOption[] {
    if (this.users) {
      let items = this.users.items;
      if (callbackfn) {
        items = items.filter(callbackfn)
      };
      return items.map(this.mapToOption);
    }
    return [];
  }

  mapToOption(item: GetUserOutput): IDropdownOption {
    return {
      key: item.id,
      text: item.fullName
    }
  }
}

export default UserStore;