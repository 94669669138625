import { ItemType } from "../services/dto/itemType";
import { SolutionDto } from "../services/solution/dto/solutionDto";
import SolutionService from "../services/solution/solutionService";
import { defaultComponent } from "./componentStore";
import { CrudStoreBase } from "./crudStoreBase";
import { defaultTreatment } from "./treatmentStore";

class SolutionStore extends CrudStoreBase<SolutionDto> {
	constructor() {
		super(SolutionService, defaultSolution);
	}
}

export const defaultSolution = {
	id: 0,
	calculationId: 0,
	name: "",
	componentType: "General",
	programPrice: 0,
	fcr: 0,
	averageBodyWeight: 0,
	benefitsSummary: 0,
	gain: 0,
	description: "",
	profits: [],
	orderDetails: [],
	solutionTimelines: [],
	solutionItems: [],
	public: false,
};

export const defaultItem = {
	id: 0,
	component: defaultComponent,
	componentId: 0,
	treatment: defaultTreatment,
	treatmentId: 0,
	itemType: ItemType.Component
}

export const defaultSolutionItem = {
	id: 0,
	name: '',
	day: 0,
	week: 0,
	waterUsage: 0,
	dose: 0,
	dosePerDay: 0,
	price: 0,
	pricePerDay: 0,
	type: '',
	itemId: 0,
	item: defaultItem
}

export default SolutionStore;