// import { CrudStoreBase } from './crudStoreBase';
// import { CustomerDto } from '../services/customer/customerDto';
// import CustomerService from '../services/customer/customerService';
// import { PagedResultRequestDto } from '../services/base/pagedResultRequestDto';

class CustomerStore {//extends CrudStoreBase<CustomerDto>{
	// constructor() {
	// 	super(CustomerService, defaultCustomer)
	// }

	// public async getAllRegistered(pagedFilterAndSortedRequest: PagedResultRequestDto, customerGroupId: string) {
	// 	return CustomerService.getAllRegistered(pagedFilterAndSortedRequest, customerGroupId);
	// }
}

// export const defaultCustomer = {
// 	id: '',
// 	Id: '',
// 	// CustomerGuid: string,
// 	Username: '',
// 	Email: '',
// 	// AdminComment: string,
// 	// IsTaxExempt: boolean,
// 	// FreeShipping: boolean,
// 	// AffiliateId: string,
// 	// VendorId: string,
// 	// StoreId: string,
// 	Active: false,
// 	// Deleted: boolean,
// 	// Gender: string,
// 	FirstName: '',
// 	LastName: '',
// 	DateOfBirth: null,
// 	Company: '',
// 	StreetAddress: '',
// 	StreetAddress2: '',
// 	ZipPostalCode: '',
// 	City: '',
// 	CountryId: '',
// 	StateProvinceId: '',
// 	Phone: '',
// 	Fax: '',
// 	VatNumber: '',
// 	// VatNumberStatusId: string,
// 	// Groups: string[],
// 	// ShoppingCartItems: ShoppingCartItemDto[],
// 	Addresses: [],
// 	CreatedOnUtc: '',
// 	LastActivityDateUtc: ''
// }

export default CustomerStore;