import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import { mergeStyleSets, MessageBarType, PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import { myTheme } from "../../../styles/theme";
import { L } from "../../../lib/abpUtility";
import "./signInButton.css"
import AppConsts from "../../../lib/appconst";
import { Container } from "../../../stores/storeInitializer";
import UserStore from "../../../stores/userStore";
import { AzureB2CStorage, AzureResponse } from "./azureResponse";
import { useState } from "react";
import { authStore } from "../../../stores/authenticationStore";
import { getSpinnerStyles } from "../../../utils/stylesUtils";
/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
declare var abp: any;
declare var window: any;
export const AzureB2CStorageKey = "AzureB2CStorageKey";

const classNames = mergeStyleSets({
    primarySignInButton: {
        padding: '30px 100px !important',
        margin: '50px auto !important',
        fontSize: '20px',
        background: myTheme.palette.white,
        color: myTheme.palette.black,
        borderRadius: '100px',
        transition: 'all 120ms',
    },
});

export interface ISignInButtonProps {
    setMessageBarData?: (hide: boolean, text: string, type: MessageBarType) => void;
    messageBarVisibleAndNotSuccess: boolean;
}  

export const SignInButton = (props: ISignInButtonProps) => {
    const { instance, accounts } = useMsal();
    const [state, setState] = useState({customActionButtonDisabled: false});

    const checkRoles = (roles: string[]): boolean => {
        let response: boolean = false;
        const allowedRoles: string[] = ['ADMIN', 'USER', 'MANAGER', 'GUEST'];
    
        roles.some((role: string) => {
            if(allowedRoles.includes(role)) {
                response = true;
                return true;
            }
            return false;
        });

        return response;
    }

    const setToken = async (response: AzureResponse) => {
        let tokenExpireDate = new Date(new Date().getTime() + 1000 * response.account.idTokenClaims.exp);
        abp.auth.setToken(response.idToken, tokenExpireDate);
        abp.utils.setCookieValue(AppConsts.authorization.encrptedAuthTokenName, response.idToken, tokenExpireDate, abp.appPath);
        let users = await Container.getStore<UserStore>("UserStore").getAllWithKeyword(response.account.username);
        
        if(Array.isArray(users) && users.length > 0) {
            if(!users[0].roleNames || users[0].roleNames.length === 0 || !checkRoles(users[0].roleNames)) {
                if(props.setMessageBarData) {
                    props.setMessageBarData(false, L('Your account is waiting for approval by the administrator. Please be patient.'), MessageBarType.warning);
                } else {
                    console.warn(L('Your account is waiting for approval by the administrator. Please be patient.'));
                }
                authStore.logout();
                setState(pv => ({ ...pv, customActionButtonDisabled: false }));
            } else {
                localStorage.setItem(AzureB2CStorageKey, JSON.stringify({ b2c: response, user: users[0] } as AzureB2CStorage));
                window.location = '/';
            }
        } else {
            if(props.setMessageBarData) {
                props.setMessageBarData(false, L('Login failed, user not found.'), MessageBarType.error);
            }
            console.error("Login failed, users array:", users);
            setState(pv => ({ ...pv, customActionButtonDisabled: false }));
        }
    }

    const login = () => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            setToken(response as AzureResponse);
        }).catch((e) => {
            console.error(e)
            instance.acquireTokenPopup(request).then((response) => {
                setToken(response as AzureResponse);
            }).catch((e) => {
                console.error(e);
                setState(pv => ({ ...pv, customActionButtonDisabled: false }));
            });
        });
    }

    instance.handleRedirectPromise().then((authResult) => {
        if(authResult !== null){
            setToken(authResult as AzureResponse);               
        }                                      
      }).catch((error) => {
            console.error(error);
      });

    const handleLogin = (loginType: string) => {
        setState(pv => ({ ...pv, customActionButtonDisabled: true }));

        if (loginType === "popup") {
            instance.loginPopup(loginRequest).then(() => {
                login();
            }).catch(e => {
                console.error(e);
                setState(pv => ({ ...pv, customActionButtonDisabled: false }));
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {
                console.error(e);
                setState(pv => ({ ...pv, customActionButtonDisabled: false }));
            });
        }
    }

    return (
        <div className="signInButton">            
             <PrimaryButton theme={myTheme} text={L('Sign in')} onClick={() => handleLogin("redirect")} className={`${classNames.primarySignInButton} form-btn signInButton__button`} disabled={state.customActionButtonDisabled || props.messageBarVisibleAndNotSuccess} />
            {state.customActionButtonDisabled && <Spinner label={L('Please wait...')} className={getSpinnerStyles().loadSpinnerContrast} size={SpinnerSize.large} ariaLive="assertive" labelPosition="bottom" />}
        </div>
    )
}