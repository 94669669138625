import { CrudStoreBase } from './crudStoreBase';
import ProfitService from '../services/profit/profitService';
import { ProfitDto } from '../services/profit/dto/profitDto';
import { ProfitType } from '../services/dto/profitType';

class ProfitStore extends CrudStoreBase<ProfitDto>{
	constructor() {
		super(ProfitService, defaultProfit)
	}
}

export const defaultProfit = {
	id: 0,
	isArchival: false,
	profitType: ProfitType[ProfitType.ImprovingGrowth],
    rate: 0,
    weight: 0,
    price: 0,
    sum: 0,
}

export default ProfitStore;