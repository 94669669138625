import { ODataServiceBase } from '../base/oDataServiceBase';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { RaceDto } from './dto/raceDto';

export class RaceService extends ODataServiceBase<RaceDto> {
    constructor() {
        super(Endpoint.Race);
        this.internalHttp = httpApi;
    }
}

export default new RaceService();