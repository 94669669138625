import { CrudStoreBase } from './crudStoreBase';
import { RoleDto } from '../services/role/dto/roleDto';
import roleService from '../services/role/roleService';
import { CreateRoleInput } from '../services/role/dto/createRoleInput';

class RoleStore extends CrudStoreBase<RoleDto, CreateRoleInput>{
  constructor() {
    super(roleService, defaultRole)
  }
}

export const defaultRole = {
  id: 0,
  name: '',
  displayName: '',
  normalizedName: '',
  description: '',
  grantedPermissions: [],
}

export default RoleStore;