export default class Endpoint {
    baseApiUrl = "api/services/app";
    dataSet: string = "";
    restFull: boolean;

    constructor(set: string, baseApiUrl = "api/services/app", restFull = false) {
        this.dataSet = set;
        this.baseApiUrl = baseApiUrl;
        this.restFull = restFull;
    }

    Get(id: string | number = ""): string {
        let restFullId = id ? `/${id}` : "";
        return `${this.baseApiUrl}/${this.dataSet}${this.Rest('Get')}${restFullId}`;
    }
    GetAll(): string {
        return `${this.baseApiUrl}/${this.dataSet}${this.Rest('GetAll')}`;
    }
    Create(): string {
        return `${this.baseApiUrl}/${this.dataSet}${this.Rest('Create')}`;
    }
    Update(): string {
        return `${this.baseApiUrl}/${this.dataSet}${this.Rest('Update')}`;
    }
    Delete(): string {
        return `${this.baseApiUrl}/${this.dataSet}${this.Rest('Delete')}`;
    }

    Rest(ending: string) {
        return this.restFull ? '' : `/${ending}`;
    }

    Custom(path: string, withoutBaseApiUrl?: boolean): string {
        return !!withoutBaseApiUrl ? `${this.dataSet}/${path}` : `${this.baseApiUrl}/${this.dataSet}/${path}`;
    }

    static Pattern: Endpoint = new Endpoint("Pattern");
    static BlobStorage: Endpoint = new Endpoint("BlobStorage");

    // Farmwet
    static CustomerGroup: Endpoint = new Endpoint("CustomerGroup", "odata", true);
    static Language: Endpoint = new Endpoint("Language", "odata", true);
    static Country: Endpoint = new Endpoint("Country", "odata", true);
    // Domain specific
    static Customer: Endpoint = new Endpoint("Customer", "odata", true);
    static Component: Endpoint = new Endpoint("Components", "odata", true);
    
    // Farmwet abp
    static User: Endpoint = new Endpoint("User");
    static Role: Endpoint = new Endpoint("Role");
    static Profit: Endpoint = new Endpoint("Profit");
    static Animal: Endpoint = new Endpoint("Animals", "odata", true);
    static WaterUsage: Endpoint = new Endpoint("WaterUsages", "odata", true);
    static Program: Endpoint = new Endpoint("Programs", "odata", true);
    static Calculation: Endpoint = new Endpoint("Calculations", "odata", true);
    static Treatment: Endpoint = new Endpoint("Treatments", "odata", true);
    static Race: Endpoint = new Endpoint("Races", "odata", true);
    static Solution: Endpoint = new Endpoint("Solutions", "odata", false);
}