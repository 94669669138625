import { CrudStoreBase } from './crudStoreBase';

import { RaceDto } from '../services/race/dto/raceDto';
import  RaceService  from '../services/race/raceService';

class RaceStore extends CrudStoreBase<RaceDto>{
	constructor() {
		super(RaceService, defaultRace)
	}
}

export const defaultRace = {
	id: 0,
	isArchival:false,
	name:""
}

export default RaceStore;