import { httpGrandNode } from '../httpService';
import { EntityDto } from '../dto/entityDto';
import { PagedResultDto } from '../dto/pagedResultDto';
import { PagedResultRequestDto } from "./pagedResultRequestDto";
import Endpoint from '../endpoint';
import { CrudServiceBase } from './crudServiceBase';

export class ODataServiceBase<TDto extends EntityDto> extends CrudServiceBase<TDto> {
    constructor(endpoint: Endpoint) {
        super(endpoint, httpGrandNode);
    }
    
    public createDataModel(result:any){
        let toReturn;
        if(result.data.value) {
            toReturn = result.data.value;
        } else if(result.data.result && result.data.result.items && result.data.result.items.length > 0) {
            result.data.result.items.forEach((element: any) => {
                if(element.Id) { element.id = element.Id; }
            });
            toReturn = result.data.result.items;
        } else {
            result.data.forEach((element: any) => {
                if(element.Id) { element.id = element.Id; }
            });
            toReturn = result.data;
        }
        return toReturn;
    }

    public async get(entityDto: TDto): Promise<TDto> {
        let result = await this.internalHttp.get(this.endpoint.Custom(`${entityDto['Id'] ? entityDto['Id'] : entityDto['id']}`));
        
        if(result.data.value) {
            if(result.data.value.length > 0) {
                if(result.data.value[0].Id) {
                    result.data.value[0].id = result.data.value[0].Id;
                }
                return result.data.value[0];
            } else {
                return result.data.value;
            }
        } else {
            if(result.data.Id) {
                result.data.id = result.data.Id;
            }
            return result.data;
        }
    }

    public async getAll(pagedFilterAndSortedRequest: PagedResultRequestDto): Promise<PagedResultDto<TDto>> {
        let result = await this.internalHttp.get(this.endpoint.GetAll());
        
        let toReturn = this.createDataModel(result);

        return {
            totalCount: toReturn.length,
            items: toReturn
        };
    }

    public async create(createUserInput: TDto) {
        let result = await this.internalHttp.post(this.endpoint.Create(), createUserInput);
        return result.data;
    }

    public async update(updateUserInput: TDto) {
        let result = await this.internalHttp.patch(this.endpoint.Custom(`${updateUserInput.id}`), updateUserInput);
        return result.data;
    }

    public async delete(entityDto: TDto) {
        let result = await this.internalHttp.delete(this.endpoint.Custom(`${entityDto.id}`));
        return result.data;
    }

    public async getDto(entityDto: TDto) {
        let result = await this.internalHttp.get(`${this.endpoint.dataSet}/Dto/${entityDto.id}`);
        return result.data;
    }

    public async getAllDtos(filters: string | undefined = undefined, top: number | undefined = undefined, skip: number | undefined = undefined) {

        let result = await this.internalHttp.get(`${this.endpoint.dataSet}/AllDtos`, {params: {filters, top, skip}});

        let toReturn = this.createDataModel(result);

        return {
            totalCount: toReturn.length,
            items: toReturn
        };
    }
}
