import { authStore } from "../stores/authenticationStore";
import jwt_decode from "jwt-decode";

declare var abp: any;

export function isUserLoggedIn() {
    const allowedPathNames: string[] = ['/', '/user/login', '/account/resetpassword', '/account/confirmemail', 
                                        '/account/terms-of-use', '/account/privacy-policy'];

    if(!authStore.isLoggedIn && !allowedPathNames.includes(window.location.pathname)) {
        authStore.logout();
        window.location.href = '/';
    }
}

export function jwtCheckIfExpired(token: string): boolean {
    const decodedToken: any = jwt_decode(token);

    if(decodedToken.exp && Date.now() >= decodedToken.exp * 1000) {
        return true;
    } else {
        return false;
    }
}

export async function isUserHasAdminRole(): Promise<boolean> {
    let hasAdminRole = abp.auth.isGranted("User.Create");
    return hasAdminRole;
}