import { parseJsonStringToDto } from '../../utils/inputUtils';
import { getPartialModel } from '../../utils/modelUtils';
import { ODataServiceBase } from '../base/oDataServiceBase';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { ProgramDto } from './dto/programDto';

export class ProgramService extends ODataServiceBase<ProgramDto> {
    constructor() {
        super(Endpoint.Program);
        this.internalHttp = httpApi;
    }

    public async getDto(entityDto: ProgramDto) {
        let result = await this.internalHttp.get(`${this.endpoint.dataSet}/Dto/${entityDto.id}`);
        result.data.duration = Math.round(result.data.duration / 7 * 100 ) / 100;
        return result.data;
    }

    public async getAllDtos(filters: string | undefined = undefined) {

        let result = await this.internalHttp.get(`${this.endpoint.dataSet}/AllDtos${(filters) ? filters : ""}`);

        let toReturn = this.createDataModel(result);

        for (const dto of toReturn) {
            dto.duration = Math.round(dto.duration / 7 * 100 ) / 100;
        }

        return {
            totalCount: toReturn.length,
            items: toReturn
        };
    }

    public async create(createUserInput: ProgramDto) {
        if(createUserInput['profitParameters']) {
            let tempValuesFromJsonString = parseJsonStringToDto(createUserInput['profitParameters'], 'ProgramProfitParameters');
            createUserInput.growthImprovement = tempValuesFromJsonString.growthImprovement;
            createUserInput.fall = tempValuesFromJsonString.fall;
            createUserInput.foodUsage = tempValuesFromJsonString.foodUsage;
        }

        const filteredModel = getPartialModel(createUserInput, [], ['id', 'component', 'profitParameters', 'programComponentsGeneral', 'programComponentsSuplement', 'programComponentsCustom']);

        filteredModel.components = Object.keys(filteredModel.components).length > 0 ? Object.values(filteredModel.components) : [];
        filteredModel.duration = filteredModel.duration * 7;
        
        let result = await this.internalHttp.post(this.endpoint.Create(), filteredModel);
        return result.data;
    }

    public async update(updateUserInput: ProgramDto) {
        if(updateUserInput['profitParameters']) {
            let tempValuesFromJsonString = parseJsonStringToDto(updateUserInput['profitParameters'], 'ProgramProfitParameters');
            updateUserInput.growthImprovement = tempValuesFromJsonString.growthImprovement;
            updateUserInput.fall = tempValuesFromJsonString.fall;
            updateUserInput.foodUsage = tempValuesFromJsonString.foodUsage;
        }

        const filteredModel = getPartialModel(updateUserInput, [], ['component', 'profitParameters', 'programComponentsGeneral', 'programComponentsSuplement', 'programComponentsCustom']);

        filteredModel.components = Object.keys(filteredModel.components).length > 0 ? Object.values(filteredModel.components) : [];
        filteredModel.duration = filteredModel.duration * 7;
        // filteredModel.components.forEach((programComponent: ProgramComponentDto) => {
        //     programComponent.component.treatments = Object.keys(programComponent.component.treatments).length > 0 ? Object.values(programComponent.component.treatments) : [];
        //     programComponent.component.replacements = Object.keys(programComponent.component.replacements).length > 0 ? Object.values(programComponent.component.replacements) : [];
        // });

        let result = await this.internalHttp.patch(this.endpoint.Custom(`${filteredModel.id}`), filteredModel);
        return result.data;
    }
}

export default new ProgramService();