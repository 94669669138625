// import { CrudStoreBase } from './crudStoreBase';
// import { LanguageDto } from '../services/language/languageDto';
// import LanguageService from '../services/language/languageService';

 class LanguageStore {//extends CrudStoreBase<LanguageDto>{
// 	constructor() {
// 		super(LanguageService, defaultLanguage)
// 	}
 }

// export const defaultLanguage = {
// 	id: '',
// 	name: '',
// 	languageCulture: '',
// 	uniqueSeoCode: '',
// 	flagImageFileName: '',
// 	rtl: false,
// 	defaultCurrencyId: '',
// 	published: true,
// 	displayOrder: 0,
// }

export default LanguageStore;