import { L } from '../../lib/abpUtility';
import LoadableComponent from './../Loadable/index';

export const RouterPath = {
  About: 'about',
  CustomerList: 'customer-list',
  Customer: 'customer',
  UserList: 'user-list',
  RoleList: 'role-list',
  Role: 'role',
  Profile: 'profile',
  ComponentList: 'component-list',
  Component: 'component',
  AnimalList: 'animal-list',
  Animal: 'animal',
  WaterUsageList: 'water-usage-list',
  WaterUsage: 'water-usage',
  CureList: 'cure-list',
  Cure: 'cure',
  VaccineList: 'vaccine-list',
  Vaccine: 'vaccine',
  RaceList: 'race-list',
  Race: 'race',
  Program: 'program',
  ProgramList: 'program-list',
  CalculationList: 'calculation-list',
  Calculation: 'calculation',
  NewCalculation: 'new-calculation',
  Solution: 'solution',
  SolutionList: 'solution-list',
  Pattern: 'pattern',
  PatternList: 'pattern-list',
}

export const userRouter: any = [
  {
    path: '/user',
    name: 'user',
    title: 'User',
    component: LoadableComponent(() => import('../../components/Layout/UserLayout')),
    isLayout: true,
    showInMenu: false,
  },
  {
    path: '/Account/privacy-policy',
    name: 'privacy-policy',
    title: 'Privacy Policy',
    component: LoadableComponent(() => import('../../scenes/Account/PrivacyPolicy')),
    showInMenu: false,
  },
  {
    path: '/Account/terms-of-use',
    name: 'terms-of-use',
    title: 'Terms of Use',
    component: LoadableComponent(() => import('../../scenes/Account/TermsOfUse')),
    showInMenu: false,
  },
  {
    path: '/user/login',
    name: 'login',
    title: 'LogIn',
    component: LoadableComponent(() => import('../../scenes/Login')),
    showInMenu: false,
  },
  {
    path: '/Account/ConfirmEmail',
    name: 'ConfirmEmail',
    permission: '',
    title: 'ConfirmEmail',
    component: LoadableComponent(() => import('../../scenes/Account/ConfirmEmail')),
    showInMenu: false,
  },
  {
    path: '/Account/ResetPassword',
    name: 'ResetPassword',
    permission: '',
    title: 'ResetPassword',
    component: LoadableComponent(() => import('../../scenes/Account/ResetPassword')),
    showInMenu: false,
  }
];

export const appRouters: any = [
  {
    path: '/',
    exact: true,
    name: 'home',
    permission: '',
    title: L('Home'),
    icon: 'home',
    component: LoadableComponent(() => import('../../components/Layout/AppLayout')),
    isLayout: true,
    showInMenu: false,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    title: L('Home'),
    icon: 'Home',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/Dashboard')),
  },
  {
    path: `/${RouterPath.NewCalculation}`,
    permission: 'Calculation.Create',
    title: L('New calculation'),
    name: `${RouterPath.NewCalculation}`,
    icon: '',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/NewCalculation')),
  },
  {
    path: `/${RouterPath.NewCalculation}/:id?`,
    permission: 'Calculation.Create',
    title: L('New calculation'),
    name: `${RouterPath.NewCalculation}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/NewCalculation')),
  },
  {
    path: `/${RouterPath.Calculation}/:id`,
    permission: 'Calculation.Create',
    title: L('Calculation'),
    name: `${RouterPath.Calculation}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/Calculation')),
  },
  {
    path: `/${RouterPath.CalculationList}`,
    permission: 'Calculation.Create',
    title: L('Calculation list'),
    name: `${RouterPath.CalculationList}`,
    icon: '',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/CalculationList')),
  },
  {
    path: `/${RouterPath.Program}/:id`,
    permission: 'Program.Create',
    title: L('Program'),
    name: `${RouterPath.Program}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/Program')),
  },
  {
    path: `/${RouterPath.ProgramList}`,
    permission: 'Program.Create',
    title: L('Program list'),
    name: `${RouterPath.ProgramList}`,
    icon: '',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/ProgramList')),
  },
  {
    path: `/${RouterPath.Solution}/:id`,
    permission: 'Solution.Create',
    title: L('Solution'),
    name: `${RouterPath.Solution}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/Solution')),
  },
  {
    path: `/${RouterPath.SolutionList}`,
    permission: 'Solution.Create',
    title: L('History'),
    name: `${RouterPath.SolutionList}`,
    icon: '',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/SolutionList')),
  },
  {
    path: `/${RouterPath.ComponentList}`,
    permission: 'Component.Create',
    title: L('Component list'),
    name: `${RouterPath.ComponentList}`,
    icon: '',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/ComponentList')),
  },
  {
    path: `/${RouterPath.Component}/:id`,
    permission: 'Component.Create',
    title: L('Component'),
    name: `${RouterPath.Component}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/Component')),
  },
  {
    path: `/${RouterPath.CureList}`,
    permission: 'Treatment.Create',
    title: L('Cure list'),
    name: `${RouterPath.CureList}`,
    icon: '',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/CureList')),
  },
  {
    path: `/${RouterPath.Cure}/:id`,
    permission: 'Treatment.Create',
    title: L('Cure'),
    name: `${RouterPath.Cure}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/Cure')),
  },
  {
    path: `/${RouterPath.VaccineList}`,
    permission: 'Treatment.Create',
    title: L('Vaccine list'),
    name: `${RouterPath.VaccineList}`,
    icon: '',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/VaccineList')),
  },
  {
    path: `/${RouterPath.Vaccine}/:id`,
    permission: 'Treatment.Create',
    title: L('Vaccine'),
    name: `${RouterPath.Vaccine}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/Vaccine')),
  },
  {
    path: `/${RouterPath.WaterUsageList}`,
    permission: 'WaterUsage.Create',
    title: L('Water usage list'),
    name: `${RouterPath.WaterUsageList}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/WaterUsageList')),
  },
  {
    path: `/${RouterPath.WaterUsage}/:id`,
    permission: 'WaterUsage.Create',
    title: L('Water usage'),
    name: `${RouterPath.WaterUsage}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/WaterUsage')),
  },
  {
    path: `/${RouterPath.AnimalList}`,
    permission: 'Animal.Create',
    title: L('Animal list'),
    name: `${RouterPath.AnimalList}`,
    icon: '',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/AnimalList')),
  },
  {
    path: `/${RouterPath.Animal}/:id`,
    permission: 'Animal.Create',
    title: L('Animal'),
    name: `${RouterPath.Animal}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/Animal')),
  },
  {
    path: `/${RouterPath.RaceList}`,
    permission: 'Race.Create',
    title: L('Race list'),
    name: `${RouterPath.RaceList}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/RaceList')),
  },
  {
    path: `/${RouterPath.Race}/:id`,
    permission: 'Race.Create',
    title: L('Race'),
    name: `${RouterPath.Race}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/Race')),
  },
  {
    path: `/${RouterPath.UserList}`,
    permission: 'User.Create',
    title: L('User list'),
    name: `${RouterPath.UserList}`,
    icon: '',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/UserList')),
  },
  {
    path: `/${RouterPath.RoleList}`,
    permission: 'Role.Create',
    title: L('Role list'),
    name: `${RouterPath.RoleList}`,
    icon: '',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/RoleList')),
  },
  {
    path: `/${RouterPath.Role}/:id`,
    permission: 'Role.Create',
    title: L('Role'),
    name: `${RouterPath.Role}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/Role')),
  },
  {
    path: `/${RouterPath.PatternList}`,
    permission: 'Pattern.Create',
    title: L('Pattern list'),
    name: `${RouterPath.PatternList}`,
    icon: '',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/PatternList')),
  },
  {
    path: `/${RouterPath.Pattern}/:id`,
    permission: 'Pattern.Create',
    title: L('Pattern'),
    name: `${RouterPath.Pattern}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/Pattern')),
  },
  {
    path: `/${RouterPath.About}`,
    title: L('About'),
    name: `${RouterPath.About}`,
    icon: 'Info',
    showInMenu: true,
    component: LoadableComponent(() => import('../../scenes/About')),
  },
  {
    path: '/logout',
    permission: '',
    title: 'Logout',
    name: 'logout',
    icon: 'info-circle',
    showInMenu: false,
    component: LoadableComponent(() => import('../../components/Logout')),
  },
  {
    path: '/exception?:type',
    permission: '',
    title: 'exception',
    name: 'exception',
    icon: 'info-circle',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/Exception')),
  },  
  {
    path: `/${RouterPath.Profile}`,
    title: L('Profile'),
    name: `${RouterPath.Profile}`,
    icon: '',
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/Profile')),
  },
];

export const routers = [...userRouter, ...appRouters];