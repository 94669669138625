export function getPartialModel(model: any, keysToReturn: string[], keysToObey?: string[], deepSearch: boolean = true): any { 
    let partialModel: any = {};
    
    if(keysToObey && keysToObey.length > 0) {
        for (let key in model) {
            if(typeof model[key] === 'object' && deepSearch === true) {
                model[key] = getPartialModel(model[key], [], keysToObey);
            }

            if(!keysToObey.includes(key)) {
                partialModel[key] = model[key];
            }
        }
    } else if(keysToReturn && keysToReturn.length > 0) {
        for (let key in model) {
            if(keysToReturn.includes(key)) {
                partialModel[key] = model[key];
            }
        }
    } else {
        partialModel = model;
    }

    return partialModel;
}