import { Route, Switch, withRouter } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import utils from '../../utils/utils';
import { isUserLoggedIn } from '../../utils/authUtils';

const Router = ({ history }: {history: any}) => {
  const UserLayout = utils.getRoute('/user').component;
  const AppLayout = utils.getRoute('/').component;

  history.listen((location: any, action: any) => {
    // location is an object like window.location // console.log(action, location.pathname, location.state);
    isUserLoggedIn();
  });

  return (
    <Switch>
      <Route path="/user" render={(props: any) => <UserLayout {...props} />} />
      <Route path="/account" render={(props: any) => <UserLayout {...props} />} />
      <ProtectedRoute path="/" render={(props: any) => <AppLayout {...props} exact />} />
    </Switch>
  );
};

export default withRouter(Router);