import { ODataServiceBase } from '../base/oDataServiceBase';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { TreatmentDto } from './dto/treatmentDto';

export class TreatmentService extends ODataServiceBase<TreatmentDto> {
    constructor() {
        super(Endpoint.Treatment);
        this.internalHttp = httpApi;
    }
}

export default new TreatmentService();