import { CrudStoreBase } from './crudStoreBase';
import PatternService from '../services/pattern/patternService';
import { PatternDto } from '../services/pattern/dto/patternDto';
import { PatternType } from '../services/dto/patternType';

class PatternStore extends CrudStoreBase<PatternDto>{
	constructor() {
		super(PatternService, defaultPattern)
	}
}

export const defaultPattern = {
	id: 0,
	patternType: PatternType.AboutUs,
    value: '',
    description: '',
}

export default PatternStore;