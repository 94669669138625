import { IDropdownOption } from "@fluentui/react";
import { DoseType } from "../services/dto/doseType";
import { ProgramComponentDto } from "../services/program/dto/programComponentDto";
import { ReplacementDto } from "../services/replacement/dto/replacementDto";
import { TreatmentCalculationDto } from "../services/treatment/dto/treatmentCalculationDto";
import { TreatmentComponentDto } from "../services/treatment/dto/treatmentComponentDto";
import { DailyWaterUsageDto } from "../services/waterUsage/dto/dailyWaterUsageDto";
import { defaultTreatmentCalculation } from "../stores/calculationStore";
import { defaultReplacement } from "../stores/componentStore";
import { getSourceDataFromUserFields } from "./storeUtils";
import { filterBySome, isJsonString } from "./utils";

export function getInputIconData(attribute: any): any { 
    let tempIconData: any = {};
    let userFieldsFound: number = 0;

    attribute.UserFields.some((UserField: any) => {
        if(UserField.Key === 'displayIcon') {
            tempIconData['iconName'] = UserField.Value;
            userFieldsFound++;
        }
        if(UserField.Key === 'iconOnClick') {
            tempIconData['iconOnClick'] = UserField.Value;
            userFieldsFound++;
        }

        if(userFieldsFound === 2) {
            return true;
        }
        return false;
    });

    return tempIconData;
}

export function getDropdownOptionsFromDataSource(attribute: any, dataSource: any, sortBy: string, gnLanguage: any): any { 
    let tempOptions: IDropdownOption[] = [];

    let Value: string | undefined;
    Value = filterBySome(attribute.UserFields, 'Key', 'dataSource');
    if(Value) tempOptions = getSourceDataFromUserFields(dataSource[Value], sortBy, gnLanguage);

    return tempOptions;
}

export function getInputValidationData(attribute: any): any { 
    let tempValidationData: any = {};
    let catchValueForKeys: string[] = ['disabled', 'readOnly', 'required', 'multiline', 'errorMessage', 'maxlength', 'minlength',
                                        'mask', 'maskFormatKey', 'maskFormatValue', 'maskChar', 'placeholder', 'prefix', 'suffix',
                                        'inputType', 'min', 'max', 'autoincrement'];

    attribute.UserFields.forEach((UserField: any) => {
        if(catchValueForKeys.includes(UserField.Key)) {
            tempValidationData[UserField.Key] = UserField.Value;
        }
    });

    return tempValidationData;
}

export function getInputTableData(attribute: any): any { 
    let tempTableData: any = {};

    let catchValueForKeys: string[] = ['tableRowsCountFromInputId', 'tableColumnHeaders', 'tableColumnInputs'];

    attribute.UserFields.forEach((UserField: any) => {
        if(catchValueForKeys.includes(UserField.Key)) {
            tempTableData[UserField.Key] = UserField.Value;
        }
    });

    return tempTableData;
}

export function isInputInTab(UserFields: any): boolean { 
    let response = false;

    UserFields.some((UserField: any) => {
        if(UserField.Key.substr(0, 4) === "step") {
            response = true;
            return true;
        }
        return false;
    });

    return response;
}

export function shouldSaveAsTemplateForTable(attrId: string, UserFields: any, savedTemplates: any): boolean | string {
    let response = false;

    UserFields.some((UserField: any) => {
        if(UserField.Key === "templateInputForTableId") {
            if(savedTemplates[UserField.Value]) {
                let attrIdFound: boolean = false;
                savedTemplates[UserField.Value].some((element: any) => {
                    if(element.id === attrId) {
                        attrIdFound = true;
                        return true;
                    }
                    return false;
                });

                if(!attrIdFound) {
                    response = UserField.Value;
                }
            } else {
                response = UserField.Value;
            }

            return true;
        }
        return false;
    });

    return response;
}

export function shouldSaveAsTemplateForCalculationAdjust(attrId: string, UserFields: any): boolean | string {
    let response: boolean | string = false;

    UserFields.some((UserField: any) => {
        if(UserField.Key === "allowTuningForInsurer" && UserField.Value.length > 0) {
            response = attrId;  
            return true;
        }
        return false;
    });

    return response;
}

export function presetTableInputData(jsonValue: string, valuesToPreset: any[]): string {
    let resultObj = {};

    if(jsonValue && typeof jsonValue === 'string') {
        resultObj = JSON.parse(jsonValue);
    }

    if(!(valuesToPreset.length > 0) || !(valuesToPreset[0].values.length > 0)) return JSON.stringify(resultObj);

    let valuesToSet = valuesToPreset[0].inputIdToPreset.length;
    let rowsToPreset = valuesToPreset[0].values[0].length;

    valuesToPreset[0].values.forEach((values: any[]) => {
        if(values.length !== rowsToPreset) throw new Error("All values must have same lengths");
    });

    for(let i=0; i < valuesToSet; i++){
        for(let j = 0; j < rowsToPreset; j++) {
            if(!resultObj[j]) {
                resultObj[j] = {};
            }
            valuesToPreset.forEach((valueToPreset: any) => {
                resultObj[j][valueToPreset['inputIdToPreset'][i]] = valueToPreset['values'][i][j];
            });
        }
    }

    return JSON.stringify(resultObj);
}

export function parseJsonStringToDto(jsonString: string, dtoName: string): any {
    let returnValue: any;

    if(isJsonString(jsonString)) {
        let parsedValue = JSON.parse(jsonString);
    
        switch(dtoName) {
            case "DailyWaterUsageDto":
                returnValue = [] as DailyWaterUsageDto[];
                
                for(let key in parsedValue) {
                    if(parsedValue.hasOwnProperty(key)) {
                        let tempObj: any = {};
    
                        let day = parsedValue[key] && parsedValue[key]['dailyWaterUsageDay'] && typeof parsedValue[key]['dailyWaterUsageDay'] === 'string' 
                                    ? parseInt(parsedValue[key]['dailyWaterUsageDay']) : 0;
                        let quantity = parsedValue[key] && parsedValue[key]['dailyWaterUsageQuantity'] && typeof parsedValue[key]['dailyWaterUsageQuantity'] === 'string'
                                    ? parseInt(parsedValue[key]['dailyWaterUsageQuantity']) : 0;
                        let isArchival = parsedValue[key] && parsedValue[key]['dailyWaterUsageIsArchival']
                                    ? parsedValue[key]['dailyWaterUsageIsArchival'] : false;
    
                        tempObj['day'] = day;
                        tempObj['quantity'] = quantity;
                        tempObj['isArchival'] = isArchival;
                        tempObj['id'] = parsedValue[key]['dailyWaterUsageId'] ? parsedValue[key]['dailyWaterUsageId'] : 0;
    
                        returnValue.push(tempObj);
                    }
                }
                break;
            case "ProgramComponentDto":
                returnValue = [] as ProgramComponentDto[];

                for(let key in parsedValue) {
                    if(parsedValue.hasOwnProperty(key)) {
                        let tempObj: any = {};

                        let basic = parsedValue[key] && parsedValue[key]['programComponentBasic'] 
                                    ? Number(parsedValue[key]['programComponentBasic']) : 0;
                        let optimal = parsedValue[key] && parsedValue[key]['programComponentOptimal']
                                    ? Number(parsedValue[key]['programComponentOptimal']) : 0;
                        let maximum = parsedValue[key] && parsedValue[key]['programComponentMaximum']
                                    ? Number(parsedValue[key]['programComponentMaximum']) : 0;
                        let days = parsedValue[key] && parsedValue[key]['programComponentDays'] && typeof parsedValue[key]['programComponentDays'] === 'string'
                                    ? parsedValue[key]['programComponentDays'] : "";
                        let name = parsedValue[key] && parsedValue[key]['programComponentName'] && typeof parsedValue[key]['programComponentName'] === 'string'
                                    ? parsedValue[key]['programComponentName'] : "";
    
                        tempObj['basic'] = basic;
                        tempObj['optimal'] = optimal;
                        tempObj['maximum'] = maximum;
                        tempObj['days'] = days;
                        tempObj['name'] = name;
    
                        returnValue.push(tempObj);
                    }
                }
                break;
            case "TreatmentComponentDto":
                returnValue = [] as TreatmentComponentDto[];

                for(let key in parsedValue) {
                    if(parsedValue.hasOwnProperty(key)) {
                        let tempObj: any = {};

                        let skipBefore = parsedValue[key] && parsedValue[key]['treatmentComponentSkipBefore'] 
                                    ? Number(parsedValue[key]['treatmentComponentSkipBefore']) : 0;
                        let skipAfter = parsedValue[key] && parsedValue[key]['treatmentComponentSkipAfter']
                                    ? Number(parsedValue[key]['treatmentComponentSkipAfter']) : 0;
                        let doseType = parsedValue[key] && parsedValue[key]['treatmentComponentDoseType']
                                    ? parsedValue[key]['treatmentComponentDoseType'] : "";
    
                        tempObj['skipBefore'] = skipBefore;
                        tempObj['skipAfter'] = skipAfter;
                        tempObj['doseType'] = doseType;
    
                        returnValue.push(tempObj);
                    }
                }
                break;
            case "ProgramProfitParameters":
                returnValue = {} as any;
                
                for(let key in parsedValue) {
                    if(parsedValue.hasOwnProperty(key)) {
                        switch(Number(key)) {
                            case 0:
                                returnValue['growthImprovement'] = parsedValue[key].profitParameterIndicator ? Number(parsedValue[key].profitParameterIndicator) : 0;
                                break;
                            case 1:
                                returnValue['fall'] = parsedValue[key].profitParameterIndicator ? Number(parsedValue[key].profitParameterIndicator) : 0;
                                break;
                            case 2:
                                returnValue['foodUsage'] = parsedValue[key].profitParameterIndicator ? Number(parsedValue[key].profitParameterIndicator) : 0;
                                break;
                        }
                    }
                }
                break;
            case "ReplacementDto":
                returnValue = [] as ReplacementDto[];

                for(let key in parsedValue) {
                    if(parsedValue.hasOwnProperty(key)) {
                        let tempObj: any = {...defaultReplacement};

                        let name = parsedValue[key] && parsedValue[key]['replacementName']
                                    ? parsedValue[key]['replacementName'] : "";
                        let quantity = parsedValue[key] && parsedValue[key]['replacementQuantity']
                                    ? Number(parsedValue[key]['replacementQuantity']) : 0;

                        tempObj['name'] = name;
                        tempObj['quantity'] = quantity;
                        tempObj['id'] = parsedValue[key]['replacementId'] ? parsedValue[key]['replacementId'] : 0;
                        tempObj['isArchival'] = parsedValue[key]['replacementIsArchival'] ? parsedValue[key]['replacementIsArchival'] : false;

                        returnValue.push(tempObj);
                    }
                }
                break;
            case "TreatmentCalculationDto":
                returnValue = [] as TreatmentCalculationDto[];

                for(let key in parsedValue) {
                    if(parsedValue.hasOwnProperty(key)) {
                        let tempObj: any = {...defaultTreatmentCalculation};

                        let startDay = parsedValue[key] && parsedValue[key]['treatmentCalculationStartDay']
                                    ? Number(parsedValue[key]['treatmentCalculationStartDay']) : 0;
                        let quantityDays = parsedValue[key] && parsedValue[key]['treatmentCalculationQuantityDays']
                                    ? Number(parsedValue[key]['treatmentCalculationQuantityDays']) : 0;
                        let id = parsedValue[key] && parsedValue[key]['treatmentCalculationId']
                                    ? Number(parsedValue[key]['treatmentCalculationId']) : 0;
                        let treatmentId = parsedValue[key] && parsedValue[key]['treatmentCalculationTreatmentId']
                                    ? Number(parsedValue[key]['treatmentCalculationTreatmentId']) : 0;
                        let doseType = parsedValue[key] && parsedValue[key]['treatmentCalculationDoseType']
                                    ? parsedValue[key]['treatmentCalculationDoseType'] : DoseType[DoseType.None];
                        let treatment = parsedValue[key] && parsedValue[key]['treatmentCalculationTreatment']

                        tempObj['startDay'] = startDay;
                        tempObj['quantityDays'] = quantityDays;
                        tempObj['id'] = id;
                        tempObj['treatmentId'] = treatmentId;
                        tempObj['doseType'] = doseType;
                        tempObj['treatment'] = treatment;

                        returnValue.push(tempObj);
                    }
                }
                break;
            case "CalculationComponentDto":
                returnValue = [];

                for(let key in parsedValue) {
                    if(parsedValue.hasOwnProperty(key)) {
                        let tempObj: any = {};
                        let basic = parsedValue[key] && parsedValue[key]['calculationComponentBasic'] 
                                    ? parseFloat(parsedValue[key]['calculationComponentBasic']) : 0;
                        let optimal = parsedValue[key] && parsedValue[key]['calculationComponentOptimal']
                                    ? parseFloat(parsedValue[key]['calculationComponentOptimal']) : 0;
                        let maximum = parsedValue[key] && parsedValue[key]['calculationComponentMaximum']
                                    ? parseFloat(parsedValue[key]['calculationComponentMaximum']) : 0;
                        let days = parsedValue[key] && parsedValue[key]['calculationComponentDays'] && typeof parsedValue[key]['calculationComponentDays'] === 'string'
                                    ? parsedValue[key]['calculationComponentDays'] : "";
                        let name = parsedValue[key] && parsedValue[key]['calculationComponentName'] && typeof parsedValue[key]['calculationComponentName'] === 'string'
                                    ? parsedValue[key]['calculationComponentName'] : "";
                        let id = parsedValue[key] && parsedValue[key]['calculationComponentId']
                                    ? parseFloat(parsedValue[key]['calculationComponentId']) : 0;
                        let componentId = parsedValue[key] && parsedValue[key]['calculationComponentComponentId'] ? parseFloat(parsedValue[key]['calculationComponentComponentId']) : 0;
                        let component = parsedValue[key] && parsedValue[key]['calculationComponent']
                        let isArchival = parsedValue[key] && parsedValue[key]['calculationComponentIsArchival'] === true
                        
                        tempObj['id'] = id;
                        tempObj['isArchival'] = isArchival;
                        tempObj['basic'] = basic;
                        tempObj['optimal'] = optimal;
                        tempObj['maximum'] = maximum;
                        tempObj['days'] = days;
                        tempObj['name'] = name;
                        tempObj['componentId'] = componentId;
                        tempObj['component'] = component;

                        returnValue.push(tempObj);
                    }
                }
            }

        return returnValue;
    } else {
        return jsonString;
    }
}

export function parseDtoToJsonString(dto: any, dtoName: string): string {
    let returnValue: string = "";

    if(typeof dto !== 'string' && !!dto) {    
        let newDtoObj: any = [];

        switch(dtoName) {
            case "DailyWaterUsageDto":
                for(let key in dto) {
                    if(dto.hasOwnProperty(key)) {
                        newDtoObj[key] = { 
                            'dailyWaterUsageDay': dto[key].day,
                            'dailyWaterUsageQuantity': dto[key].quantity,
                            'dailyWaterUsageIsArchival': dto[key].isArchival,
                        };

                        if(dto[key].id) {
                            newDtoObj[key]['dailyWaterUsageId'] = parseInt(dto[key].id);
                        }
                    }
                }

                returnValue = JSON.stringify(newDtoObj);
                break;
            case "ProgramComponentDto":
                for(let key in dto) {
                    if(dto.hasOwnProperty(key)) {
                        newDtoObj[key] = {
                            'programComponentBasic': dto[key].basic,
                            'programComponentOptimal': dto[key].optimal,
                            'programComponentMaximum': dto[key].maximum,
                            'programComponentDays': dto[key].days,
                        };

                        if(!!dto[key].component && dto[key].component.name) {
                            newDtoObj[key]['programComponentName'] = dto[key].component.name;
                        }
                    }
                }

                returnValue = JSON.stringify(newDtoObj);
                break;
            case "TreatmentComponentDto":
                for(let key in dto) {
                    if(dto.hasOwnProperty(key)) {
                        newDtoObj[key] = {
                            'treatmentComponentSkipBefore': dto[key].skipBefore,
                            'treatmentComponentSkipAfter': dto[key].skipAfter,
                            // 'treatmentComponentDoseType': dto[key].doseType,
                        };

                        if(!!dto[key].doseType) {
                            newDtoObj[key]['treatmentComponentDoseType'] = dto[key].doseType;
                        }

                        if(!!dto[key].treatment && dto[key].treatment.name) {
                            newDtoObj[key]['treatmentComponentName'] = dto[key].treatment.name;
                        }
                    }
                }

                returnValue = JSON.stringify(newDtoObj);
                break;
            case "ProgramProfitParameters":
                for(let key in dto) {
                    if(dto.hasOwnProperty(key) && key === 'profitParameters') {
                        let parsedProfitParameters = JSON.parse(dto[key]);

                        parsedProfitParameters.forEach((profitParam: any, index: number) => {
                            switch(index) {
                                case 0:
                                    profitParam.profitParameterIndicator = profitParam.profitParameterIndicator ? Number(profitParam.profitParameterIndicator) : dto.growthImprovement;
                                    break;
                                case 1:
                                    profitParam.profitParameterIndicator = profitParam.profitParameterIndicator ? Number(profitParam.profitParameterIndicator) : dto.fall;
                                    break;
                                case 2:
                                    profitParam.profitParameterIndicator = profitParam.profitParameterIndicator ? Number(profitParam.profitParameterIndicator) : dto.foodUsage;
                                    break;
                            }
                        });

                        newDtoObj = parsedProfitParameters;
                    }
                }

                if(newDtoObj.length === 0) {
                    let profitParameters: any[] = [
                        { 'profitParameterIndicator': dto.growthImprovement },
                        { 'profitParameterIndicator': dto.fall },
                        { 'profitParameterIndicator': dto.foodUsage },
                    ];
                    newDtoObj = profitParameters;
                }

                returnValue = JSON.stringify(newDtoObj);
                break;
            case "ReplacementDto":
                for(let key in dto) {
                    if(dto.hasOwnProperty(key)) {
                        newDtoObj[key] = {
                            'replacementName': dto[key].name,
                            'replacementQuantity': dto[key].quantity,
                            'replacementId': dto[key].id,
                            'replacementIsArchival': dto[key].isArchival,
                        };
                    }
                }

                returnValue = JSON.stringify(newDtoObj);
                break;
            case "TreatmentCalculationDto":
                for(let key in dto) {
                    if(dto.hasOwnProperty(key)) {
                        newDtoObj[key] = {
                            'treatmentCalculationStartDay': dto[key].startDay,
                            'treatmentCalculationQuantityDays': dto[key].quantityDays,
                            'treatmentCalculationId': parseInt(dto[key].id),
                            'treatmentCalculationTreatmentId': dto[key].treatmentId ? dto[key].treatmentId : dto[key].treatment.id,
                            'treatmentCalculationDoseType': dto[key].doseType ? dto[key].doseType : DoseType[DoseType.None],
                            'treatmentCalculationTreatment' : dto[key].treatment ? dto[key].treatment : null,
                        };
                    }
                }

                returnValue = JSON.stringify(newDtoObj);
                break;
            case "CalculationComponentDto":
                for(let key in dto) {
                    if(dto.hasOwnProperty(key)) {
                        newDtoObj[key] = {
                            'calculationComponentBasic': dto[key].basic,
                            'calculationComponentOptimal': dto[key].optimal,
                            'calculationComponentMaximum': dto[key].maximum,
                            'calculationComponentDays': dto[key].days,
                            'calculationComponentId': dto[key].id,
                            'calculationComponentComponentId': dto[key].componentId,
                            'calculationComponentIsArchival': dto[key].isArchival,
                        };

                        if(!!dto[key].component && dto[key].component.name) {
                            newDtoObj[key]['calculationComponentName'] = dto[key].component.name;
                        }
                    }
                }

                returnValue = JSON.stringify(newDtoObj);
            }
    } else {
        returnValue = dto as string;
    }

    return returnValue;
}

export function numberAsStringWithSpaces(inputData: string): string {
    if(!!inputData && inputData !== null){
        let result = inputData.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

        return result;
    }
    return inputData;
}

export function numberAsStringWithoutSpaces(inputData: string): string {
    if(!!inputData && inputData !== null){
        let result = inputData.toString().replace(/ /g, '');
        return result;
    }
    return inputData;
}