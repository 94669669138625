import { CrudStoreBase } from './crudStoreBase';
import { UserDto } from '../services/user/dto/userDto';
import userCrudService from '../services/user/userCrudService';
import { action, observable } from 'mobx';
import userService from '../services/user/userService';
import { RoleDto } from '../services/role/dto/roleDto';

class UserCrudStore extends CrudStoreBase<UserDto>{
  constructor() {
    super(userCrudService, defaultUser)
  }
  @observable roles: RoleDto[] = [];
  
  @action
  async getRoles() {
    let result = await userService.getRoles();
    this.roles = result;
  }
}

export const defaultUser = {
  id: 0,
  name: '',
  userName: '',
  surname: '',
  emailAddress: '',
  isActive: false,
  roleNames: [],
  password: '',
  phoneNumber: '',
  isEmailConfirmed: false,
  avatarUrl: '',
}

export default UserCrudStore;