import { getPartialModel } from '../../utils/modelUtils';
import { ODataServiceBase } from '../base/oDataServiceBase';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { ComponentDto } from './dto/componentDto';

export class ComponentService extends ODataServiceBase<ComponentDto> {
    constructor() {
        super(Endpoint.Component);
        this.internalHttp = httpApi;
    }

    public async create(createUserInput: ComponentDto) {
        const filteredModel = getPartialModel(createUserInput, [], ['id', 'treatment', 'treatmentComponentCure', 'treatmentComponentVaccine', 'replacementsList']);
        
        filteredModel.treatments = Object.keys(filteredModel.treatments).length > 0 ? Object.values(filteredModel.treatments) : [];
        filteredModel.replacements = Object.keys(filteredModel.replacements).length > 0 ? Object.values(filteredModel.replacements) : [];
        
        let result = await this.internalHttp.post(this.endpoint.Create(), filteredModel);
        return result.data;
    }

    public async update(updateUserInput: ComponentDto) {
        const filteredModel = getPartialModel(updateUserInput, [], ['treatment', 'treatmentComponentCure', 'treatmentComponentVaccine', 'replacementsList']);

        filteredModel.treatments = Object.keys(filteredModel.treatments).length > 0 ? Object.values(filteredModel.treatments) : [];        
        filteredModel.replacements = Object.keys(filteredModel.replacements).length > 0 ? Object.values(filteredModel.replacements) : [];
        
        let result = await this.internalHttp.patch(this.endpoint.Custom(`${filteredModel.id}`), filteredModel);
        return result.data;
    }
}

export default new ComponentService();