import { CrudStoreBase } from './crudStoreBase';
import { WaterUsageDto } from '../services/waterUsage/dto/waterUsageDto';
import WaterUsageService from '../services/waterUsage/waterUsageService';
import { defaultAnimal } from './animalStore';

class WaterUsageStore extends CrudStoreBase<WaterUsageDto>{
	constructor() {
		super(WaterUsageService, defaultWaterUsage)
	}
}

export const defaultWaterUsage = {
	id: 0,
	note: '',
	name: '',
	animalId: 0,
	animal: defaultAnimal,
	dailyWaterUsages: [],
}

export default WaterUsageStore;