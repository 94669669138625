import { ProgramDto } from '../services/program/dto/programDto';
import ProgramService from '../services/program/programService';
import { defaultComponent } from './componentStore';
import { CrudStoreBase } from './crudStoreBase';

class ProgramStore extends CrudStoreBase<ProgramDto>{
	constructor() {
		super(ProgramService, defaultProgram)
	}
}

export const defaultProgramComponent = {
	id: 0,
	name: '',
	component: defaultComponent,
	componentId: 0,
	basic: 0,
	days: '',
	isArchival: false,
	maximum: 0,
	optimal: 0
}

export const defaultProgram = {
	id: 0,
	name: '',
	startDay: 0,
	duration: 4,
	components: [],
	note: '',
	growthImprovement: 0,
	fall: 0,
	foodUsage: 0,
	fcr: false,
	massImprovement: false,
	public: false,
}

export default ProgramStore;