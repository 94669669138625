import { getPartialModel } from '../../utils/modelUtils';
import { ODataServiceBase } from '../base/oDataServiceBase';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { CalculationDto } from './dto/calculationDto';

export class CalculationService extends ODataServiceBase<CalculationDto> {
    constructor() {
        super(Endpoint.Calculation);
        this.internalHttp = httpApi;
    }

    public async getAllDtos(filters: string | undefined = undefined) {
        let result = await this.internalHttp.get(`${this.endpoint.dataSet}/AllDtos${(filters) ? filters : ""}`);

        let toReturn = this.createDataModel(result);

        for (const dto of toReturn) {
            dto.programEndDay = Math.round(dto.programEndDay / 7 * 100 ) / 100;
        }
        return {
            totalCount: toReturn.length,
            items: toReturn
        };
    }

    public async getDto(entityDto: CalculationDto) {
        let result = await this.internalHttp.get(`${this.endpoint.dataSet}/Dto/${entityDto.id}`);
        if(result && result.data && result.data.programEndDay)
            result.data.programEndDay = Math.round(result.data.programEndDay / 7 * 100) / 100;
        return result.data;
    }

    public async create(createUserInput: CalculationDto) {
        const filteredModel = getPartialModel(createUserInput, [], ['id', 'userId', 'animal', 'program', 'user', 'treatment', "component"]);

        filteredModel.treatments = Object.keys(filteredModel.treatments).length > 0 ? Object.values(filteredModel.treatments) : [];
        filteredModel.components = Object.keys(filteredModel.components).length > 0 ? Object.values(filteredModel.components) : [];
        filteredModel.startDate = filteredModel.startDate.split("T")[0];
        filteredModel.programEndDay = filteredModel.programEndDay * 7;

        let result = await this.internalHttp.post(this.endpoint.Create(), filteredModel);
        return result.data;
    }

    public async update(updateUserInput: CalculationDto) {
        const filteredModel = getPartialModel(updateUserInput, [], ['userId', 'animal', 'program', 'user', 'treatment', 'component']);

        filteredModel.treatments = Object.keys(filteredModel.treatments).length > 0 ? Object.values(filteredModel.treatments) : [];
        filteredModel.components = Object.keys(filteredModel.components).length > 0 ? Object.values(filteredModel.components) : [];
        filteredModel.startDate = filteredModel.startDate.split("T")[0];
        filteredModel.programEndDay = filteredModel.programEndDay * 7;

        let result = await this.internalHttp.patch(this.endpoint.Custom(`${filteredModel.id}`), filteredModel);
        return result;
    }
}

export default new CalculationService();