import * as abpTypings from '../lib/abp';

import { L } from '../lib/abpUtility';
import { routers } from '../components/Router/router.config';
import { IDropdownOption } from '@fluentui/react';
import moment from 'moment';
import AppConfig from '../lib/appconfig';

declare var abp: any;

class Utils {
  loadScript(url: string) {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    document.body.appendChild(script);
  }

  extend(...args: any[]) {
    let options,
      name,
      src,
      srcType,
      copy,
      copyIsArray,
      clone,
      target = args[0] || {},
      i = 1,
      length = args.length,
      deep = false;
    if (typeof target === 'boolean') {
      deep = target;
      target = args[i] || {};
      i++;
    }
    if (typeof target !== 'object' && typeof target !== 'function') {
      target = {};
    }
    if (i === length) {
      target = this;
      i--;
    }
    for (; i < length; i++) {
      if ((options = args[i]) !== null) {
        for (name in options) {
          src = target[name];
          copy = options[name];
          if (target === copy) {
            continue;
          }
          srcType = Array.isArray(src) ? 'array' : typeof src;
          if (deep && copy && ((copyIsArray = Array.isArray(copy)) || typeof copy === 'object')) {
            if (copyIsArray) {
              copyIsArray = false;
              clone = src && srcType === 'array' ? src : [];
            } else {
              clone = src && srcType === 'object' ? src : {};
            }
            target[name] = this.extend(deep, clone, copy);
          } else if (copy !== undefined) {
            target[name] = copy;
          }
        }
      }
    }

    return target;
  }

  getPageTitle = (pathname: string) => {
    const route = routers.filter(route => route.path === pathname);
    const localizedAppName = 'Farmwet';
    if (!route || route.length === 0) {
      return localizedAppName;
    }

    return L(route[0].title) + ' | ' + localizedAppName;
  };

  getRoute = (path: string): any => {
    return routers.filter(route => route.path === path)[0];
  };

  setLocalization() {
    if (!abp.utils.getCookieValue('Abp.Localization.CultureName')) {
      let language = navigator.language.substring(0,2).toLowerCase();
      abp.utils.setCookieValue('Abp.Localization.CultureName', language, new Date(new Date().getTime() + 5 * 365 * 86400000), abp.appPath);
    }
  }

  getCurrentClockProvider(currentProviderName: string): abpTypings.timing.IClockProvider {
    if (currentProviderName === 'unspecifiedClockProvider') {
      return abp.timing.unspecifiedClockProvider;
    }

    if (currentProviderName === 'utcClockProvider') {
      return abp.timing.utcClockProvider;
    }

    return abp.timing.localClockProvider;
  }

  addIfMissing = <TItem extends unknown>(
    item: TItem,
    itemSource: TItem[],
    idPropName: keyof TItem
  ): TItem[] => {
    if (!itemSource || item == null) {
      console.error(
        `Item source (${itemSource}) or adding item (${item}) is incorrect`
      );
    }
    return !itemSource?.some((x) => x[idPropName] === item[idPropName])
      ? [...itemSource, item]
      : itemSource;
  };
}

export default new Utils();

export async function asyncForEach<T>(array: Array<T>, callback: (item: T, index: number, array: Array<T>) => Promise<void>) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

type EnumType = { [s: number]: string };
export function mapEnum(enumerable: EnumType, fn: Function, enumMemberType: string = "number"): any[] {
  let enumMembers: any[] = Object.keys(enumerable).map(key => enumerable[key]);
  let enumValues: number[] = enumMembers.filter(v => typeof v === enumMemberType);
    return enumValues.map((value: number, i: number) => fn(enumMembers[i], value));
}

export function enumToDropdownOptions(enumerable: EnumType, valueAsKey: boolean = false, translated: boolean = false, enumMemberType: string = "number"): IDropdownOption[] {
  return mapEnum(enumerable, (member: string, value: number) => {
      return { key: (valueAsKey ? value : member), text: translated ? L(member) : member }
  }, enumMemberType);
}

export function dateFormat(date: string, format: string = "DD.MM.YYYY, HH:mm"): string {
  return moment(date).format(format);
}

export function mapPathToIcon(pathName: string): string {
  return AppConfig.pagePathToIcon[pathName] ? AppConfig.pagePathToIcon[pathName] : null;
}

export function catchErrorMessage(error: any): string {
  if (typeof error.response !== 'undefined' && typeof error.response.data !== 'undefined' && typeof error.response.data.error !== 'undefined' &&
      typeof error.response.data.error.message !== 'undefined' && error.response.data.error.message.length > 0) {
      // Request made and server responded - error.response.data / error.response.status / error.response.headers
      return L(error.response.data.error.message);
  } else if (typeof error.request !== 'undefined' && error.request.length > 0) {
      // The request was made but no response was received
      return L(error.request);
  } else if (typeof error.message !== 'undefined' && error.message.length > 0) {
      // Something happened in setting up the request that triggered an Error
      return L(error.message);
  } else {
      console.error(error);
      return L("Error datails in developer console.");
  }
}

export function filterBySome(array: any, keyToCompare: string, valueToCompare: any) {
  let arrayItemToReturn;
  
  array.some((arrayItem: any) => {
    if(arrayItem[keyToCompare] === valueToCompare) {
      arrayItemToReturn = arrayItem;
        return true;
    }
    return false;
  });

  return arrayItemToReturn;
}

export function isJsonString(str: string): boolean {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}