import { CrudStoreBase } from './crudStoreBase';
import { AnimalDto } from '../services/animal/animalDto';
import AnimalService from '../services/animal/animalService';
import { defaultRace } from './raceStore';

class AnimalStore extends CrudStoreBase<AnimalDto>{
	constructor() {
		super(AnimalService, defaultAnimal)
	}
}

export const defaultAnimal = {
	id: 0,
	name: '',
	raceId: 0,
	race: defaultRace,
	isArchival: false,
}

export default AnimalStore;