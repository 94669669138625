import AppConsts from './../lib/appconst';
import { L } from '../lib/abpUtility';
import axios from 'axios';
import './httpService.less'
import { Container } from '../stores/storeInitializer';

const qs = require('qs');

declare var abp: any;

const http = axios.create({
  baseURL: AppConsts.remoteServiceBaseUrl,
  timeout: 30000,
  headers: {
    "Accept-Language": abp.utils.getCookieValue('Abp.Localization.CultureName')
  },
  paramsSerializer: function (params) {
    return qs.stringify(params, {
      encode: false,
    });
  },
});

export const httpApi = axios.create({
  baseURL: `${AppConsts.remoteServiceBaseUrl}`,
  timeout: 1000 * 60 * 1,
  paramsSerializer: function (params) {
    return qs.stringify(params, {
      encode: false,
    });
  },
});

export const httpGrandNode = axios.create({
  // TODO move GranNodeBaseURL to conifg
  baseURL: 'https://top-grandnode.germanywestcentral.cloudapp.azure.com/',
  timeout: 30000,
  paramsSerializer: function (params) {
    return qs.stringify(params, {
      encode: false,
    });
  },
});

// todo add authorization headers
const httpDownload = axios.create({
  baseURL: AppConsts.remoteServiceBaseUrl,
  timeout: 30000,
  method: 'GET',
  responseType: 'blob'
});

export var download = async function (resource: string, name: string) {
  var response = await httpDownload.get(resource);
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
}

var requestInterceptor = function (config: any) {
  if (!!abp.auth.getToken()) {
    config.headers.common['Authorization'] = 'Bearer ' + abp.auth.getToken();
  }

  config.headers.common['.AspNetCore.Culture'] = abp.utils.getCookieValue('Abp.Localization.CultureName');
  config.headers.common['Abp.TenantId'] = abp.multiTenancy.getTenantIdCookie();

  return config;
}

var errorRequestInterceptor = function (error: any) {
  return Promise.reject(error);
}

var successResponseInterceptor = function (response: any) {
  if (Container.EventBus)
    Container.EventBus.clearHttpError()
  return response;
}
var errorResponseInterceptor = function (error: any) {
  var errorObj: any = {};
  if (!!error.response && !!error.response.data.error && !!error.response.data.error.message && error.response.data.error.details) {
    errorObj = {
      title: error.response.data.error.message,
      content: error.response.data.error.details,
    };
  } else if (!!error.response && !!error.response.data.error && !!error.response.data.error.message) {
    errorObj = {
      title: error.response.data.error.message,
    };
  } else if (!error.response) {
    errorObj = { content: L('UnknownError') };
  }

  if (Container && Container.EventBus) {
    if (Container.EventBus.customErrorHandling) {
      Container.EventBus.setHttpError(errorObj)
      return Promise.resolve({ data: { result: undefined } });
    }
  }

  // todo Add Fluent Modal 

  console.error(errorObj)
  // Modal.error(errorObj)

  setTimeout(() => { }, 1000);

  return Promise.reject(error);
}

http.interceptors.request.use(requestInterceptor, errorRequestInterceptor);
httpApi.interceptors.request.use(requestInterceptor, errorRequestInterceptor);
httpGrandNode.interceptors.request.use(requestInterceptor, errorRequestInterceptor);

http.interceptors.response.use(successResponseInterceptor, errorResponseInterceptor);
httpApi.interceptors.response.use(successResponseInterceptor, errorResponseInterceptor);
httpGrandNode.interceptors.response.use(successResponseInterceptor, errorResponseInterceptor);

export default http;
