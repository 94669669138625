import { getPartialModel } from '../../utils/modelUtils';
import { ODataServiceBase } from '../base/oDataServiceBase';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { AnimalDto } from './animalDto';

export class AnimalService extends ODataServiceBase<AnimalDto> {
    constructor() {
        super(Endpoint.Animal);
        this.internalHttp = httpApi;
    }

    public async create(createUserInput: AnimalDto) {
        const filteredModel = getPartialModel(createUserInput, [], ['race']);

        let result = await this.internalHttp.post(this.endpoint.Create(), filteredModel);
        return result.data;
    }
}

export default new AnimalService();