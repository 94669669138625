import { ODataServiceBase } from '../base/oDataServiceBase';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { ProfitDto } from './dto/profitDto';

export class ProfitService extends ODataServiceBase<ProfitDto> {
    constructor() {
        super(Endpoint.Profit);
        this.internalHttp = httpApi;
    }
    public async getAllDtos(): Promise<{ totalCount: any; items: any; }> {        
        let result = await this.internalHttp.get(this.endpoint.GetAll());
        return result.data;
    } 
    public async getDto(entityDto: ProfitDto): Promise<any> {
        let result = await this.internalHttp.get(this.endpoint.Get(), {params:{"id": entityDto.id}});
        return result.data;
    }
}
export default new ProfitService();