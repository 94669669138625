import { ODataServiceBase } from '../base/oDataServiceBase';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { SolutionDto } from './dto/solutionDto';

export class SolutionService extends ODataServiceBase<SolutionDto> {
    constructor() {
        super(Endpoint.Solution);
        this.internalHttp = httpApi;
    }

    public async getAllDtos(filters: string | undefined = undefined, top: number | undefined = 50, skip: number | undefined = 0) {
        let result = await this.internalHttp.get(`${this.endpoint.dataSet}/AllDtos`,{params:{
            filters,
            top,
            skip
        }});
        
        let toReturn = this.createDataModel(result);

        return {
            totalCount: toReturn.length,
            items: toReturn
        };
    }

    public async CreateForCalculation(calculationId: number) {
        let result = await this.internalHttp.get(this.endpoint.Custom(`CreateForCalculation/${calculationId}`, true));
        return result.data;
    }

    public async UpdateForCalculation(calculationId: number) {
        let result = await this.internalHttp.get(this.endpoint.Custom(`UpdateForCalculation/${calculationId}`, true));
        return result.data;
    }

    public async GetForCalculation(calculationId: number) {
        let result = await this.internalHttp.get(this.endpoint.Custom(`GetForCalculation/${calculationId}`, true));
        return result.data;
    }

    public async CreateExcelForCalculation(calculationId: number, withoutPrices: boolean) {
        let endpointName: string = `CreateExcelForCalculation/${calculationId}`;
        if(withoutPrices){
            endpointName = `CreateExcelWithoutPricesForCalculation/${calculationId}`;
        }
        let result = await this.internalHttp.get(this.endpoint.Custom(endpointName, true));
        return result.data;
    }

    public async CreateExcelForSolution(solutionId: number, withoutPrices: boolean) {
        let endpointName: string = `CreateExcelForSolution/${solutionId}`;
        if(withoutPrices){
            endpointName = `CreateExcelWithoutPricesForSolution/${solutionId}`;
        }
        let result = await this.internalHttp.get(this.endpoint.Custom(endpointName, true));
        return result.data;
    }
}

export default new SolutionService();