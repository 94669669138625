import { parseJsonStringToDto } from '../../utils/inputUtils';
import { getPartialModel } from '../../utils/modelUtils';
import { ODataServiceBase } from '../base/oDataServiceBase';
import Endpoint from '../endpoint';
import { httpApi } from '../httpService';
import { WaterUsageDto } from './dto/waterUsageDto';

export class WaterUsageService extends ODataServiceBase<WaterUsageDto> {
    constructor() {
        super(Endpoint.WaterUsage);
        this.internalHttp = httpApi;
    }

    public async create(createUserInput: WaterUsageDto) {
        if(createUserInput.dailyWaterUsages && typeof createUserInput.dailyWaterUsages === 'string') {
            createUserInput.dailyWaterUsages = parseJsonStringToDto(createUserInput.dailyWaterUsages, 'DailyWaterUsageDto');
        }

        const filteredModel = getPartialModel(createUserInput, [], ['race']);
        filteredModel.dailyWaterUsages = Object.keys(filteredModel.dailyWaterUsages).length > 0 ? Object.values(filteredModel.dailyWaterUsages) : [];

        let result = await this.internalHttp.post(this.endpoint.Create(), filteredModel);
        return result.data;
    }

    public async update(updateUserInput: WaterUsageDto) {
        if(updateUserInput.dailyWaterUsages && typeof updateUserInput.dailyWaterUsages === 'string') {
            updateUserInput.dailyWaterUsages = parseJsonStringToDto(updateUserInput.dailyWaterUsages, 'DailyWaterUsageDto');
        }

        const filteredModel = getPartialModel(updateUserInput, [], []);
        filteredModel.dailyWaterUsages = Object.keys(filteredModel.dailyWaterUsages).length > 0 ? Object.values(filteredModel.dailyWaterUsages) : [];

        let result = await this.internalHttp.patch(this.endpoint.Custom(`${updateUserInput.id}`), filteredModel);
        return result.data;
    }
}

export default new WaterUsageService();