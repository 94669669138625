const config = (window as any)._env_;
const appVersion = (window as any).version;

const AppConsts = {
  userManagement: {
    defaultAdminUserName: 'admin',
  },
  localization: {
    defaultLocalizationSourceName: 'Farmwet',
  },
  authorization: {
    encrptedAuthTokenName: 'enc_auth_token',
  },
  appBaseUrl: config.REACT_APP_APP_BASE_URL,
  remoteServiceBaseUrl: config.REACT_APP_REMOTE_SERVICE_BASE_URL,
  clientId: config.CLIENT_ID,
  authority: config.AUTHORITY,
  aswaggerLink: config.ASWAGGER_LINK,
  version: appVersion,
};

export default AppConsts;