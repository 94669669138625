import { CrudStoreBase } from './crudStoreBase';
import { CalculationDto } from '../services/calculation/dto/calculationDto';
import CalculationService from '../services/calculation/calculationService';
import { CalculationType } from '../services/calculation/dto/calculationTypeEnum';
import { DoseType } from '../services/dto/doseType';

class CalculationStore extends CrudStoreBase<CalculationDto>{
	constructor() {
		super(CalculationService, defaultCalculation)
	}
}

export const defaultTreatmentCalculation = {
	id: 0,
	treatmentId: 0,
    treatment: null,
    doseType: DoseType[DoseType.None],
    startDay: 0,
    quantityDays: 0,
    description: "",
}

export const defaultCalculation = {
	id: 0,
	name: "",
	raceId: 0,
	race: null,
	animalId: 0,
	animal: null,
	startDate: "",
	programStartDay: 0,
	programEndDay: 4,
	programId: 0,
	program: null,
	herdSize: 0,
	description: "",
	public: false,
	userId: 0,
	user: null,
	calculationType: CalculationType[CalculationType.Basic],
	treatments: [],
	components: [],
	solutionId: 0,
	fcr: 0,
	averageBodyWeight: 0,
	price: 0,
	feedPrice: 0,
	growthImprovement: 0,
	fall: 0,
	foodUsage: 0,
}

export default CalculationStore;