import RoleStore from './roleStore';
import TenantStore from './tenantStore';
import UserStore from './userStore';
import SessionStore from './sessionStore';
import AuthenticationStore from './authenticationStore';
import AccountStore from './accountStore';
import SearchStore from './searchStore';
import EventStore from './eventStore';
import CustomerStore from './customerStore';
import UserCrudStore from './userCrudStore';
import LanguageStore from './languageStore';
import CountryStore from './countryStore';
import ComponentStore from './componentStore';
import AnimalStore from './animalStore';
import WaterUsageStore from './waterUsageStore';
import ProgramStore from './programStore';
import CalculationStore from './calculationStore';
import TreatmentStore from './treatmentStore';
import SolutionStore from './solutionStore';
import RaceStore from './raceStore';
import ProfitStore from './profitStore';
import PatternStore from './patternStore';

export class Container {
  static EventBus: EventStore;
  static stores: any

  static getStore<TStore>(name: string): TStore {
    name = name[0].toLowerCase() + name.substr(1, name.length - 1)
    return (Container.stores[name]) as TStore;
  }
}

export default function initializeStores() {
  Container.EventBus = new EventStore();
  Container.stores =  {
    authenticationStore: new AuthenticationStore(),
    roleStore: new RoleStore(),
    tenantStore: new TenantStore(),
    userStore: new UserStore(),
    sessionStore: new SessionStore(),
    accountStore: new AccountStore(),
    searchStore: new SearchStore(),
    eventStore: Container.EventBus,
    userCrudStore: new UserCrudStore(),
    languageStore: new LanguageStore(),
    countryStore: new CountryStore(),
    // domain specific
    customerStore: new CustomerStore(),
    componentStore: new ComponentStore(),
    animalStore: new AnimalStore(),
    waterUsageStore: new WaterUsageStore(),
    treatmentStore: new TreatmentStore(),
    programStore: new ProgramStore(),
    calculationStore: new CalculationStore(),
    raceStore: new RaceStore(),
    solutionStore: new SolutionStore(),
    profitStore: new ProfitStore(),
    patternStore: new PatternStore(),
  };

  return Container.stores;
}
